import {
  required,
  email,
  numeric,
  min,
  max,
  between
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { isBefore, isAfter, format, addYears, getDay } from "date-fns";
import { getPatientByBsn } from "@/api";
import { nlBE } from "date-fns/locale";

extend("required", {
  ...required,
  message: "Dit veld is verplicht"
});

extend("email", {
  ...email,
  message: "Vul een geldig e-mailadres in"
});

extend("within_year", {
  message: () => "Vul een waarde tussen 1-52 weken in",
  validate: (value) => value > 0 && value <= 52
});

extend("min_one_checkbox", {
  params: ["options"],
  message: () => "Geef minimaal één keuze op",
  validate: (value, { options }: any) =>
    options.filter((o: any) => o.selected).length > 0
});

extend("numeric", {
  ...numeric,
  message: "Dit veld mag alleen cijfers bevatten"
});

extend("min", {
  ...min,
  params: ["length"],
  message: "Dit veld moet minimaal {length} karakters bevatten"
});

extend("max", {
  ...max,
  params: ["length"],
  message: "Dit veld mag maximaal {length} karakters bevatten"
});

extend("between", {
  ...between,
  params: ["values"],
  message: (_, { values }) => {
    const limits = values.split(",");
    return `Vul een waarde in van ${limits[0]} t/m ${limits[1]}`;
  },
  validate: (value, { values }) => {
    const limits = values.split(",");

    if (limits.length === 2) {
      return value >= limits[0] && value <= limits[1];
    }

    return false;
  }
});

extend("min_value", {
  ...min,
  params: ["min"],
  message: "Dit veld moet minimaal {min} zijn",
  validate: (value, { min }: any) => {
    if (value && min) return parseInt(value) >= parseInt(min);
    return true;
  }
});

extend("max_value", {
  ...max,
  params: ["max"],
  message: "Dit veld mag maximaal {max} zijn",
  validate: (value, { max }: any) => {
    if (value && max) return parseInt(value) <= parseInt(max);
    return true;
  }
});

extend("postal_code", {
  message: () => "Vul een geldige postcode in",
  validate: (value) => {
    // postal codes containing SA/SD/SS are not valid
    const regex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
    return regex.test(value);
  }
});

extend("phone_number", {
  message: () => "Vul een valide telefoonnummer in",
  validate: (value) => {
    const regex = /^[0-9]{10}|[0-9]{13}|[0-9]{14}$/gm;
    return regex.test(value);
  }
});

extend("is_not_in_future", {
  message: () => "Datum mag niet in de toekomst liggen",
  validate: (value) => {
    return isBefore(value, new Date());
  }
});

extend("is_in_future", {
  message: () => "Datum moet in de toekomst liggen",
  validate: (value) => {
    return isAfter(value, new Date());
  }
});

extend("is_before", {
  params: ["is_before"],
  message: (value, { is_before }: any) =>
    `Datum moet voor ${format(new Date(is_before), "dd-MM-yyyy")} zijn`,
  validate: (value, { is_before }: any) => isBefore(value, is_before)
});

extend("enddate_within_year", {
  params: ["is_before"],
  message: () => "De datum mag maximaal een jaar na de startdatum liggen",
  validate: ({ startDate, endDate }) => {
    if (!startDate || !endDate) return true;
    const maxEndDate = addYears(startDate, 1);
    return isBefore(endDate, maxEndDate);
  }
});

extend("is_after", {
  params: ["is_after"],
  message: (value, { is_after }: any) =>
    `De datum moet na ${format(new Date(is_after), "dd-MM-yyyy")} zijn`,
  validate: (value, { is_after }: any) => isAfter(value, is_after)
});

extend("not_in_weekend", {
  message: () => `De datum mag niet in het weekend liggen`,
  validate: (value) => {
    if (!value) return true;

    const dayOfWeek = getDay(value);
    return dayOfWeek !== 0 && dayOfWeek !== 6;
  }
});

export const isBsnValid = (value: string) => {
  const length = value?.toString().length;
  if (length !== 8 && length !== 9) return false;
  if (length === 8) value = "0" + value;
  const d: any = value?.toString().split("");

  const sum =
    9 * d[0] +
    8 * d[1] +
    7 * d[2] +
    6 * d[3] +
    5 * d[4] +
    4 * d[5] +
    3 * d[6] +
    2 * d[7] +
    -1 * d[8];

  return sum % 11 === 0;
};

extend("bsn_valid", {
  message: () => "Deze BSN is niet valide",
  validate: (value) => isBsnValid(value)
});

extend("bsn_exists", {
  message: (value, { _value_ }: any) =>
    `Er bestaat al een patient met BSN ${_value_}`,
  validate: async (value) => {
    try {
      const patient = await getPatientByBsn(value);
      // eslint-disable-next-line
      return !(patient.data && patient.data.hasOwnProperty("id"));
    } catch (err) {
      console.error(err);
      return false;
    }
  }
});

extend("is_not_day_of_week", {
  params: ["daysUnavailable"],
  message: (value, { _value_ }: any) => {
    return `Datum mag niet op ${format(new Date(_value_), "EEEE", {
      locale: nlBE
    })} zijn`;
  },

  validate: (value, { daysUnavailable }: any) => {
    const dayOfWeek = getDay(value);

    return !daysUnavailable.some((day: number) => day === dayOfWeek);
  }
});
