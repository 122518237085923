import { Specifier } from "@/types";
import { getSpecifier } from "@/api";

export enum SpecifierActionTypes {
  SET_SPECIFIER = "SET_SPECIFIER",
  SET_LOADING = "SET_LOADING"
}

export interface SpecifierState {
  specifier: Specifier | null;
  isLoading: boolean;
}

export const initialSpecifierState: SpecifierState = {
  specifier: null,
  isLoading: false
};

const getters = {
  getSpecifier(state: SpecifierState): Specifier | null {
    return state.specifier;
  }
};

const mutations = {
  [SpecifierActionTypes.SET_SPECIFIER](
    state: SpecifierState,
    specifier: Specifier
  ) {
    state.specifier = specifier;
  },
  [SpecifierActionTypes.SET_LOADING](
    state: SpecifierState,
    isLoading: boolean
  ) {
    state.isLoading = isLoading;
  }
};

const actions = {
  async getSpecifier({ commit }: any, plazaUserId: number) {
    commit(SpecifierActionTypes.SET_LOADING, true);

    const { data } = await getSpecifier(plazaUserId);

    commit(SpecifierActionTypes.SET_SPECIFIER, data || []);
    commit(SpecifierActionTypes.SET_LOADING, false);
  }
};

export default {
  state: initialSpecifierState,
  getters,
  actions,
  mutations
};
