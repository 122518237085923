
import Vue from "vue";

export default Vue.extend({
  name: "HeroBanner",
  props: ["title", "subtitle", "cta"],
  data() {
    return {
      heroVisible: false
    };
  }
});
