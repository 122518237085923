
export default {
  data: () => ({
    error: null
  }),

  errorCaptured(error: any) {
    //@ts-ignore
    this.error = error;
  }
};
