import Vue from "vue";
import Vuex from "vuex";
import request, { RequestState } from "./modules/request";
import patients, { PatientsState } from "./modules/patients";
import user, { UserState } from "./modules/user";
import formbuilder, { FormBuilderState } from "./modules/formbuilder";
import specifier, { SpecifierState } from "@/store/modules/specifier";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";
export interface StoreState {
  user: UserState;
  request: RequestState;
  patients: PatientsState;
  formbuilder: FormBuilderState;
  specifier: SpecifierState;
}

export default new Vuex.Store({
  modules: {
    request,
    patients,
    user,
    formbuilder,
    specifier
  },
  strict: debug
});
