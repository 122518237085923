
import Vue from "vue";
import CustomHeader from "@/components/common/Header.vue";
import ErrorBoundary from "@/components/ErrorBoundary.vue";
import PatchNotesModal from "@/components/modals/PatchNotesModal.vue";
import HeroBanner from "@/components/common/HeroBanner.vue";

export default Vue.extend({
  name: "App" as string,
  components: {
    HeroBanner,
    CustomHeader,
    ErrorBoundary,
    PatchNotesModal
  },
  data() {
    return {
      isPatchNotesModalActive: false
    };
  },
  methods: {
    handleOpenModal() {
      this.isPatchNotesModalActive = true;
    }
  }
});
