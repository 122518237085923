import axios from "axios";
import {
  Program,
  Step,
  PatientDossier,
  FullRequest,
  Template,
  PlazaUser,
  ContactDetail
} from "@/types";

import { Patient } from "@eurocept/types";
import { transformDateInPayload } from "@/utils/dateUtils";
import store, { StoreState } from "../store";
import router from "@/router";
import clonedeep from "lodash.clonedeep";

export const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  timeout: process.env.VUE_APP_TIMEOUT
});

export const authenticate = async (token: string) => {
  if (!token) throw "No token specified";
  const { data: jwt } = await axios.post(
    process.env.VUE_APP_BASE_AUTH_URL + `/authenticate/${token}`
  );

  return store.dispatch("login", jwt);
};

instance.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] =
      "Bearer " + (store.state as StoreState).user.token;
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    if (response.headers["x-jwt"]) {
      store.dispatch("login", response.headers["x-jwt"]);
    }
    return response;
  },
  async (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      await store.dispatch("logout");
      router.replace({ query: {} }); // remove params
      router.push("/no-access");
    } else if (error.response?.status === 422) {
      return Promise.reject({
        message: "De ingestuurde waardes zijn niet valide"
      });
    } else if (error.response?.status === 423) {
      return Promise.reject({
        status: error.response.status,
        message: "Deze aanvraag is al ingediend"
      });
    } else if (error.response?.status === 409) {
      return Promise.reject({
        message:
          "Aanvraag kon niet worden opgeslagen omdat er een nieuwere versie bestaat. Vernieuw de pagina"
      });
    } else if (error.response?.status === 413) {
      return Promise.reject({
        message: "De verstuurde data is te groot"
      });
    } else if (error.response?.status === 424) {
      return Promise.reject({
        message: "Er is een probleem gevonden met de ingestelde rechten"
      });
    } else {
      const responseMessage = error.response?.data?.message;
      return Promise.reject({
        message: responseMessage
      });
    }
  }
);

export const getPrograms = (): Promise<{ data: Program[] }> => {
  return instance.get("/programs");
};

export const getPatientSchema = (
  epin?: string | null
): Promise<{ data: Template }> => {
  return epin
    ? instance.get(`schemas/patients/${epin}`)
    : instance.get(`schemas/patients`);
};

export const updatePatient = (epin: string, data: any): Promise<any> => {
  let patient = { ...data };
  const payload = clonedeep(patient);
  patient = transformDateInPayload(payload);
  return instance.post(`/patients/${epin}`, patient);
};

export const updatePatientNotes = (
  epin: string,
  internalNotes?: string
): Promise<any> => {
  return instance.post(`/patients/${epin}/update-notes`, {
    internal_notes: internalNotes ?? ""
  });
};

export const createPatient = (data: any): Promise<any> => {
  let patient = { ...data };
  const payload = clonedeep(patient);
  patient = transformDateInPayload(payload);
  return instance.post(`/patients`, patient);
};

type PaginationOptions = {
  query: string;
  sort: string;
  sort_dir: string;
  page: number;
  perPage: number;
};

type RequestPaginationOptions = PaginationOptions & {
  status: string;
};

type PaginationMeta = {
  current_page: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
};

export const getPatients = (
  options: PaginationOptions
): Promise<{ data: { data: Patient[]; meta: PaginationMeta } }> => {
  const params = [
    `query=${options.query}`,
    `per_page=${options.perPage}`,
    `sort=${options.sort}`,
    `sort_dir=${options.sort_dir}`,
    `page=${options.page}`
  ].join("&");

  return instance.get(`/patients?${params}`);
};

export const createRequest = (
  program_id: number,
  patient_id: number,
  extra_props?: {
    [key: string]: any;
  }
): Promise<{
  data: FullRequest;
}> => {
  return instance.post(`/requests`, {
    program_id,
    patient_id,
    extra_props
  });
};

export const getPatient = (epin: string): Promise<{ data: Patient }> => {
  return instance.get(`/patients/${epin}`);
};

export const getRequests = (
  options: RequestPaginationOptions
): Promise<{
  data: { data: FullRequest[]; meta: PaginationMeta };
}> => {
  const params = [
    `query=${options.query}`,
    `per_page=${options.perPage}`,
    `sort=${options.sort}`,
    `sort_dir=${options.sort_dir}`,
    `page=${options.page}`,
    `status=${options.status}`
  ].join("&");

  return instance.get(`/requests?${params}`);
};

export const getPatientDossier = (
  epin: string
): Promise<{ data: PatientDossier }> => {
  return instance.get(`/patients/${epin}/dossier`);
};

export const getRequestConfig = async (
  requestId: number
): Promise<{ data: FullRequest }> => {
  return instance.get(`/requests/${requestId}`);
};

export const getRequestDetails = (
  requestId: number
): Promise<{ data: Template }> => {
  return instance.get(`/schemas/requests/${requestId}/details`);
};

export const getRequestComplete = (
  requestId: number
): Promise<{ data: Template }> => {
  return instance.get(`/schemas/requests/${requestId}/confirmed`);
};

export const getSteps = (requestId: number): Promise<{ data: Step[] }> => {
  return instance.get(`/schemas/requests/${requestId}/steps`);
};

export const getSchema = (
  requestId: number,
  step: string
): Promise<{
  data: Template;
}> => {
  return instance.get(`/schemas/requests/${requestId}/steps/${step}`);
};

export const postRequestStep = (
  requestId: number,
  updateNr: number,
  stepKey: string,
  payload: any,
  complete: boolean
): Promise<any> => {
  if (!requestId || !stepKey) throw "No requestId or stepKey specified";
  const data = clonedeep(payload);
  payload = transformDateInPayload(data);
  const url = complete
    ? `/requests/${requestId}/${updateNr}/steps/${stepKey}`
    : `/requests/${requestId}/${updateNr}/steps/${stepKey}?complete=${complete}`;
  return instance.post(url, payload);
};

export const stopRequest = (
  requestId: number,
  updateNr: number,
  payload: any
): Promise<{ data: FullRequest }> => {
  const data = clonedeep(payload);
  payload = transformDateInPayload(data);
  return instance.post(`/requests/${requestId}/${updateNr}/stop`, payload);
};

export const completeRequest = (
  requestId: number,
  updateNr: number
): Promise<{ data: FullRequest }> => {
  return instance.post(`/requests/${requestId}/${updateNr}/complete`);
};

export const cancelRequest = (
  requestId: number,
  updateNr: number
): Promise<{ data: FullRequest }> => {
  return instance.post(`/requests/${requestId}/${updateNr}/cancel`);
};

export const getPatientDetails = (
  bsn: number,
  dateOfBirth: string
): Promise<any> => {
  if (!bsn || !dateOfBirth) {
    throw "No Bsn or dateOfBirth specified";
  }
  return instance.get(`/patient-details/${bsn}/${dateOfBirth}`);
};

export const deleteRequest = (
  requestId: number,
  updateNr: number
): Promise<any> => {
  return instance.delete(`/requests/${requestId}/${updateNr}`);
};

export const repeatRequest = (
  requestId: number,
  updateNr: number
): Promise<{ data: FullRequest }> => {
  return instance.post(`/requests/${requestId}/${updateNr}/repeat`);
};

export const getPatientByBsn = (bsn: number): Promise<any> => {
  return instance.get(`/patients/bsn/${bsn}`);
};

export const getPlazaUser = (): Promise<{ data: PlazaUser }> => {
  return instance.get(`/user`);
};

export const getSpecifier = (specifierId?: number): Promise<any> => {
  return instance.get(`/specifiers/${specifierId}`);
};

export const getSpecifierContactDetail = (
  specifierContactDetailId?: string
): Promise<any> => {
  return instance.get(`/specifier-contact-details/${specifierContactDetailId}`);
};

export const createSpecifierContactDetail = (
  specifierId: string,
  specifierContactDetail: ContactDetail
): Promise<any> => {
  return instance.post(`specifier-contact-details/`, {
    specifier_id: specifierId,
    ...specifierContactDetail
  });
};

export const updateSpecifierContactDetail = (
  specifierContactDetail: ContactDetail
): Promise<any> => {
  return instance.post(
    `specifier-contact-details/${specifierContactDetail.id}`,
    specifierContactDetail
  );
};

export const uploadExtraFiles = (
  requestId: number | null,
  payload: any
): Promise<any> => {
  return instance.post(`/requests/${requestId}/amend`, payload);
};
