import { instance, getPrograms, getPlazaUser } from "@/api";
import { Program, PlazaUser } from "@/types";
import { i18n } from "@/main";

export interface UserState {
  token: string;
  user: PlazaUser | null;
  programs: Program[];
}

interface ParsedToken {
  sub: number;
  iat: number;
  exp: number;
  user: User;
}

interface User {
  id: number;
  username: string;
  fullname: string;
  email: string;
  permissions: string[];
}

export enum UserActionTypes {
  LOG_IN = "LOG_IN",
  LOG_OUT = "LOG_OUT",
  SET_USER = "SET_USER",
  SET_TOKEN = "SET_TOKEN",
  GET_TOKEN = "GET_TOKEN",
  SET_PROGRAMS = "SET_PROGRAMS",
  CLEAR = "CLEAR"
}

export const initialUserState: UserState = {
  token: localStorage.getItem("token") || "",
  user: null,
  programs: []
};

const getters = {
  isLoggedIn: (state: UserState): boolean => {
    return !!state.token;
  },
  permissions: (state: UserState, getters: any): string[] => {
    return getters.parsedToken.user.permissions;
  },
  hasPermission(state: UserState, getters: any) {
    return (permission: string) =>
      getters.permissions.some((p: string) => p === permission);
  },
  parsedToken: (state: UserState): ParsedToken | "" => {
    if (!state.token) return "";
    try {
      return JSON.parse(atob(state.token.split(".")[1]));
    } catch (err) {
      return "";
    }
  },
  isTokenValid(state: UserState, getters: any) {
    return getters.parsedToken.exp > Math.round(new Date().getTime() / 1000);
  },
  isEpdUser(state: UserState, getters: any): boolean {
    return !!getters.parsedToken?.plaza_scopes?.is_epd_user;
  },
  scopedPatient(state: UserState, getters: any) {
    return getters.parsedToken?.plaza_scopes?.patient_epin;
  }
};

const mutations = {
  [UserActionTypes.SET_TOKEN](state: UserState, token: string) {
    state.token = token;
  },
  [UserActionTypes.SET_USER](state: UserState, user: PlazaUser) {
    state.user = user;
    if (user.locale) {
      i18n.locale = user.locale;
    }
  },
  [UserActionTypes.LOG_OUT](state: UserState) {
    state.token = "";
  },
  [UserActionTypes.SET_PROGRAMS](state: UserState, programs: Program[]) {
    state.programs = programs;
  },
  // eslint-disable-next-line
  [UserActionTypes.CLEAR](state: UserState) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = { ...state, ...initialUserState };
  }
};

const actions = {
  login({ commit }: any, token: string) {
    localStorage.setItem("token", token);
    commit(UserActionTypes.SET_TOKEN, token);
  },
  logout({ commit }: any) {
    localStorage.removeItem("token");
    commit(UserActionTypes.SET_TOKEN, "");
    delete instance.defaults.headers.common["Authorization"];
    commit(UserActionTypes.CLEAR);
  },
  async getUser({ commit }: any) {
    const { data } = await getPlazaUser();
    commit(UserActionTypes.SET_USER, data);
  },
  async getPrograms({ commit, state }: any) {
    if (state.programs.length === 0) {
      const response = await getPrograms();
      commit(UserActionTypes.SET_PROGRAMS, response?.data || []);
    }
  }
};

export default {
  state: initialUserState,
  getters,
  actions,
  mutations
};
