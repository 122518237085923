import { format, parse } from "date-fns";
import { nlBE } from "date-fns/locale";

//@ts-nocheck
export const transformDateInPayload = (obj: any): any => {
  if (typeof obj === "object") {
    for (const key in obj) {
      if (typeof obj[key] === "object" && !(obj[key] instanceof Date)) {
        transformDateInPayload(obj[key]);
      } else {
        if (obj[key] instanceof Date) {
          obj[key] = format(obj[key], "yyyy-MM-dd");
        }
      }
    }
  }
  return obj;
};

export const formatDate = (date: string | undefined) => {
  if (!date) return "";
  return format(new Date(date), "dd-MM-yyyy");
};

export const formatTime = (date: string | undefined) => {
  if (!date) return "";
  return format(new Date(date), "HH:mm");
};

export const formatDateTime = (date: string | undefined) => {
  if (!date) return "";
  return format(new Date(date), "dd-MM-yyyy HH:mm");
};

export const parseDate = (date: string | undefined) => {
  if (!date) return "";
  return parse(date, "dd-MM-yyyy", new Date());
};

export const formatWrittenDate = (date: string | undefined) => {
  if (!date) return "-";
  return format(new Date(date), "dd MMMM yyyy", { locale: nlBE });
};
