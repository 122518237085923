import * as api from "@/api";
import { FullRequest, Program } from "@/types";
import mixpanel from "mixpanel-browser";
import { parseDate } from "@/utils/dateUtils";
import { addDays, isAfter, subYears } from "date-fns";
import { StoreState } from "@/store";

export enum RequestActionTypes {
  SET_PROGRAM = "SET_PROGRAM",
  SET_REQUEST = "SET_REQUEST",
  SET_UPDATE_NR = "SET_UPDATE_NR"
}

export interface RequestState {
  request: FullRequest | null;
  selectedProgram: Program | null;
}

export const initialStateRequest: RequestState | null = {
  request: null,
  selectedProgram: null
};

const getters = {
  hasRequestInStore: (state: RequestState): boolean => {
    return !!state?.request?.id;
  },
  isExpired: (state: RequestState): boolean => {
    if (!state?.request?.end_date) return false;
    const isExpired = parseDate(state.request.end_date) < new Date();

    return (
      isExpired &&
      state.request.status !== "concept" &&
      state.request.status !== "completed" &&
      state.request.status !== "cancelled"
    );
  },
  isExpiring: (
    state: RequestState,
    getters: any,
    rootState: StoreState
  ): boolean => {
    if (!state?.request?.end_date) return false;
    const isExpiring =
      parseDate(state.request.end_date) <
      addDays(
        new Date(),
        rootState.user?.user?.hospital.settings.request.almost_expired_days
      );

    return (
      isExpiring &&
      state.request.status !== "concept" &&
      state.request.status !== "completed" &&
      state.request.status !== "cancelled"
    );
  },
  patientIsUnder18: (state: RequestState) => {
    if (!state.request || !state.request.patient.date_of_birth) return false;

    const dob = new Date(state.request.patient.date_of_birth);
    return isAfter(dob, subYears(new Date(), 18));
  }
};

const mutations = {
  [RequestActionTypes.SET_PROGRAM](state: RequestState, program: Program) {
    state.selectedProgram = program;
  },
  [RequestActionTypes.SET_UPDATE_NR](state: RequestState, update_nr: number) {
    state.request!.update_nr = update_nr;
  },
  [RequestActionTypes.SET_REQUEST](state: RequestState, request: FullRequest) {
    state.request = request;
  }
};

const actions = {
  async initRequest(
    { commit }: any,
    { patient, program, user, extraProps }: any
  ): Promise<FullRequest> {
    const response = await api.createRequest(
      program.id,
      patient.id,
      extraProps
    );
    commit(RequestActionTypes.SET_REQUEST, response.data);

    mixpanel.track("Create request", {
      program_id: program.id,
      program_name: program.name,
      request_id: response.data.id,
      user_role_id: user.specialism?.id,
      user_role_name: user.specialism?.name
    });

    mixpanel.time_event("Edit request step");

    return response.data;
  },

  async setRequestConfig({ commit }: any, requestId: number) {
    const { data: request } = await api.getRequestConfig(requestId);
    commit(RequestActionTypes.SET_REQUEST, request);
  }
};

export default {
  namespaced: true,
  state: initialStateRequest,
  getters,
  actions,
  mutations
};
