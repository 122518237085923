import { Patient } from "@eurocept/types";
import { isAfter, subYears } from "date-fns";

export const fullName = (p: Patient) => {
  const dob = new Date(p.date_of_birth);

  const salutation = isAfter(dob, subYears(new Date(), 18))
    ? "Kind"
    : p.gender === "M"
    ? "Dhr."
    : "Mevr.";

  return `${salutation} ${p.initials}${
    p.first_name ? " (" + p.first_name + ")" : ""
  } ${p.insertion ? `${p.insertion} ` : ""}${p.last_name}`;
};
