export enum FormBuilderActionTypes {
  SET_FORM_DATA = "SET_FORM_DATA"
}

export interface FormBuilderState {
  data: { [key: string]: any };
}

export const initialStateRequest: FormBuilderState | null = {
  data: {}
};

const getters = {};

const mutations = {
  [FormBuilderActionTypes.SET_FORM_DATA](
    state: FormBuilderState,
    data: Pick<FormBuilderState, "data">
  ) {
    state.data = { ...data };
  }
};

const actions = {};

export default {
  namespaced: true,
  state: initialStateRequest,
  getters,
  actions,
  mutations
};
