export const messages = {
  nl: {
    message: {
      header: {
        settings: "Instellingen",
        logout: "Uitloggen",
        requestCare: "Zorg aanvragen",
        patients: "Patiënten",
        requests: "Aanvragen",
        addPatient: "Patiënt toevoegen",
        toPlaza: "Naar overige diensten",
        update: "Toekomstige updates"
      },
      patchNotesModal: {
        patchNotes: "Belangrijke informatie",
        close: "Ok"
      },
      specifierView: {
        settings: "Instellingen",
        latestUpdate: "Laatste update: ",
        edit: "Bewerken",
        specifierNotFound: "Arts niet gevonden",
        username: "Gebruikersnaam",
        fullName: "Volledige naam",
        email: "E-mail",
        specialism: "Specialisme",
        hospital: "Ziekenhuis",
        division: "Divisie",
        contacts: "Contact informatie",
        user: "Gebruiker",
        noSpecifier: "Er zijn geen instellingen gevonden voor deze arts"
      },
      contactDetailsTable: {
        contactDetails: "Contact details",
        latestUpdate: "Laatste update: ",
        type: "Type",
        description: "Beschrijving",
        value: "Contact adres",
        addContactDetail: "Contact toevoegen",
        delete: "Verwijderen",
        actions: "Acties",
        edit: "Bewerken",
        out_of_use: "Buiten gebruik sinds",
        sortPlaceholder: "Sorteer op..."
      },
      specifierContactDetailForm: {
        createContactDetail: "Contact details toevoegen",
        type: "Type",
        email: "Email",
        phone: "Telefoon",
        description: "Beschrijving",
        value: "Contact adres",
        save: "Opslaan",
        cancel: "Annuleren"
      },
      deactivateContactDetailModal: {
        deactivateContactDetail: "Verwijder contact adres",
        deactivate: "Weet je zeker dat je dit contact adres wil verwijderen?",
        cancel: "Annuleren",
        deactivateIt: "Verwijderen",
        couldNotDeactivate:
          "Er ging iets mis, we konden dit contact niet deactiveren",
        deactivatedContactDetail: "Contact succesvol verwijderd"
      },
      patientDossier: {
        profile: "Profiel",
        latestUpdate: "Laatste update",
        totalNumberOfRequests: "Totaal aantal aanvragen",
        edit: "Bewerken",
        fullName: "Volledige naam",
        bsnFull: "Burgerservicenummer (BSN)",
        none: "Geen",
        dateOfBirth: "Geboortedatum",
        address: "Adres",
        phoneNumber: "Telefoonnummer",
        email: "Email adres",
        phoneNumberAlternative: "Telefoonnummer (alternatief)",
        plazaPatientId: "Plaza patient id(s)",
        insurer: "Verzekeraar",
        policyNumber: "Polisnummer",
        pharmacy: "Apotheek",
        commentsForEurocept: "Opmerkingen voor Eurocept Homecare",
        internalNotes: "Interne notities",
        notesNotVisible:
          "Deze notities zijn voor intern gebruik en zijn niet zichtbaar voor Eurocept Homecare",
        saveNotes: "Notities opslaan",
        unknown: "Onbekend",
        patientDataImported:
          "Gegevens van deze patiënt zijn middels een script gemigreerd op",
        patientNotFound: "Geen patiënt gevonden",
        requests: "Aanvragen",
        careEvents: "Zorgcontacten",
        measurements: "Meetwaarden",
        notesSaved: "Notities succesvol opgeslagen",
        errorGettingPatient: "Er ging iets mis met het ophalen van de patiënt"
      },
      patientView: {
        patientData: "Patiëntgegevens"
      },
      requestComplete: {
        noDataFound: "Geen gegevens gevonden"
      },
      requestDetails: {
        backTo: "Terug naar",
        request: "Aanvraag",
        requestManagedIn: "Deze aanvraag wordt beheerd in",
        status: "Status",
        history: "Geschiedenis"
      },
      requestPendingApproval: {
        requestSubmitted: "Uw aanvraag is ingediend",
        approvalRequested:
          "Uw aanvraag is nu aangemeld ter goedkeuring door een arts",
        requestSaved: "De aanvraag wordt voor u bewaard",
        inThe: "In het",
        patientOverview: "patiëntenoverzicht",
        canFindRequests: "kunt u deze en alle andere aanvragen terugvinden"
      },
      requestView: {
        previous: "Vorige",
        saveAndExit: "Opslaan & sluiten",
        delete: "Verwijderen",
        couldNotFindRequest:
          "Aanvraag kon niet worden opgehaald. Mogelijk bestaat deze niet meer",
        next: "Volgende",
        requestApproval: "Aanvraag indienen ter goedkeuring",
        submitRequest: "Aanvraag versturen",
        fieldsIncomplete: "Niet alle velden zijn juist ingevuld"
      },
      careEventsTable: {
        careEvents: "Zorgcontacten",
        planned: "Gepland",
        realised: "Gerealiseerd",
        contactType: "Type contact",
        transactionType: "Soort verrichting",
        noResults: "Geen resultaten gevonden",
        none: "Geen",
        newApplication: "Toediening",
        dosage: "Dosering",
        productName: "Productnaam",
        specials: {
          heart_rate_bpm: "Hartslag",
          irregular_pulse: "Pols",
          respiratory_rate: "Ademhalingsfreq (indien dysnpneu)",
          body_temperature: "Temperatuur",
          body_weight: "Gewicht",
          edema_status: "Oedeem observatie",
          iv_administration_type: "Lijn/insteekopening",
          iv_cleaned_at: "Lijn/insteekopening schoongemaakt",
          blood_pressure: "Bloeddruk",
          injection: {
            target_weight: "Streefgewicht",
            target_blood_pressure: "Streefwaarde bloeddruk"
          }
        }
      },
      errorBoundary: {
        somethingWrong: "Er ging iets fout, probeer het later opnieuw"
      },
      formBuilder: {
        requestExpired: "Deze aanvraag is verlopen",
        requestAlmostExpired: "Deze aanvraag is bijna verlopen",
        fieldsInvalid: "Sommige velden zijn niet valide",
        clearRadio: "selectie wissen"
      },
      historyList: {
        noDataAvailable: "Nog geen gegevens beschikbaar",
        updateStep: "Stap bijgewerkt",
        submit: "Aanvraag verstuurd",
        submitForApproval: "Ingediend ter goedkeuring",
        other: "Onbekende actie",
        completeRequest: "Voltooid ",
        acceptRequest: "Aanvraag geaccepteerd",
        importRequest: "Aanvraag geïmporteerd",
        cancelRequest: "Geannuleerd ",
        stopRequest: "Gestopt ",
        by: "door ",
        at: "op "
      },
      measurementsTable: {
        measurements: "Meetwaarden",
        createdAt: "Ingevoerd op",
        measurementType: "Type meting",
        noResults: "Geen resultaten gevonden"
      },
      patientDetails: {
        patientSaved: "Patiënt succesvol opgeslagen"
      },
      patientsTable: {
        patients: "Patiënten",
        searchPlaceholder: "Zoek op naam, geboortedatum of medicatie...",
        addPatient: "Patiënt toevoegen",
        sortPlaceholder: "Sorteer op...",
        name: "Naam",
        dateOfBirth: "Geboortedatum",
        internalNotes: "Interne notities",
        startDate: "Startdatum",
        endDate: "Einddatum",
        noRequests: "Geen aanvragen",
        requestExpired: "Deze aanvraag is verlopen",
        requestAlmostExpired: "Deze aanvraag is bijna verlopen",
        none: "Geen",
        lastUpdate: "Laatste wijziging",
        noResults: "Geen resultaten gevonden",
        medication: "Medicatie",
        status: "Status",
        primaryTelephone: "Primair telefoonnummer"
      },
      programSelector: {
        newRequest: "Nieuwe aanvraag",
        inactive: "(inactief)"
      },
      requestActions: {
        view: "Bekijk",
        actions: "Acties",
        finish: "Afronden",
        edit: "Bewerk",
        repeat: "Herhalen",
        stop: "Stoppen",
        cancel: "Annuleren",
        complete: "Voltooien",
        delete: "Verwijder"
      },
      requestsTable: {
        requests: "Aanvragen",
        sortBy: "Sorteer op...",
        number: "Nummer",
        medication: "Medicatie",
        startDate: "Startdatum",
        none: "Geen",
        endDate: "Einddatum",
        requestExpired: "Deze aanvraag is verlopen",
        requestAlmostExpired: "Deze aanvraag is bijna verlopen",
        prematureEnd:
          "Deze aanvraag is vroegtijdig gestopt. Oorspronkelijke einddatum: {endDate}",
        status: "Status",
        approvalBy: "Goed te keuren door: ",
        actions: "Acties",
        noResults: "Geen resultaten gevonden",
        id: "Aanvraagnummer",
        searchPlaceholder: "Zoeken..",
        statusFilterPlaceholder: "Filteren op status..",
        statusFilterShowAll: "--- Toon alles ---",
        sortPlaceholder: "Sorteer op...",
        fullName: "Naam"
      },
      addressTypes: {
        main: "woonadres",
        temporary: "tijdelijk adres",
        carefacility: "zorginstelling",
        other: "overig"
      },
      statusLabel: {
        noStatus: "Geen status",
        sent: "Ingediend",
        concept: "Concept",
        accepted: "Actief",
        expired: "Verlopen",
        pendingApproval: "Ter goedkeuring",
        stopped: "Gestopt",
        completed: "Voltooid",
        cancelled: "Geannuleerd"
      },
      stepDetails: {
        request: "Aanvraag",
        for: "voor",
        warningPendingApproval:
          "Let op: deze aanvraag is ingediend ter goedkeuring",
        lastUpdate: "Laatst bijgewerkt op"
      },
      profileDropdown: {
        myProfile: "Mijn profiel",
        username: "Gebruikersnaam",
        fullName: "Volledige naam",
        email: "E-mail",
        specialism: "Specialisme",
        hospital: "Ziekenhuis",
        division: "Divisie",
        logout: "Uitloggen",
        edit: "Bewerken"
      },
      customAddress: {
        type: "Type",
        careInstitution: "Naam van de zorginstelling",
        street: "Straat",
        houseNumber: "Huisnummer",
        houseNumberAddition: "Toevoeging",
        postalCode: "Postcode",
        city: "Plaats",
        deleteAddress: "Verwijder adres",
        addAddress: "Extra verblijfadres toevoegen",
        formatError: "Het formaat is 1011 XX"
      },
      customApplicationFrequency: {
        mandatoryFields: "Deze velden zijn verplicht",
        timesPer: "keer per",
        period: "Periode",
        days: "dagen",
        weeks: "weken",
        months: "maanden"
      },
      customBsn: {
        patientWithoutBsn: "Patiënt zonder BSN"
      },
      customCheckboxWithOther: {
        provideDescription: "Geef een duidelijke omschrijving"
      },
      customCheckboxWithOtherAndWeeklySchedule: {
        provideDescription: "Geef een duidelijke omschrijving",
        amount: "Hoeveelheid (ml)",
        comments: "Opmerkingen",
        admissionDays: "Dag(en) van toediening",
        checkDays: "Vink aan welke dagen van toepassing zijn"
      },
      customEndDateCalculation: {
        calculateEndDate:
          "Vul de velden volledig in om de einddatum te berekenen",
        dateFirstCare: "Datum eerste zorgcontact",
        firstCareDescription: `Dit is de eerstvolgende mogelijke datum waarop de toediening kan plaatsvinden.
        Indien de medicatie nog geleverd wordt dan vult u de datum van levering + 1 dag in.
        I.v.m. de doorlooptijd van het inplannen van een afspraak kan de daadwerkelijke dag van de eerste toediening niet eerder zijn dan vandaag + 4 werkdagen.`,
        frequency: "Frequentie",
        numberOfCareEvents: "Aantal zorgcontacten",
        calculatedEndDate: "Berekende einddatum",
        maxEndDate:
          "De einddatum van de behandeling is maximaal 1 jaar na de startdatum."
      },
      customFileUpload: {
        chooseFile: "Kies een bestand",
        addPrescription:
          "Voeg hier als bijlage een kopie van het door de arts ondertekende recept toe.",
        scanOrPhoto:
          "Dit kan een scan of foto zijn van een recept op papier of een uitdraai van een recept uit het EPD",
        acceptedFileTypes: "(geaccepteerde bestandsformaten: PNG, JPG of PDF).",
        tooBig: "Dit bestand is te groot. Maximale bestandsgrootte is 2MB"
      },
      customInfuusPrescription: {
        mandatoryFields: "Deze velden zijn verplicht",
        nameOfSubstance: "Stofnaam",
        mg: "mg",
        mgMl: "mg/ml"
      },
      customInfuusSchema: {
        mandatoryFields: "Deze velden zijn verplicht",
        timesPer: "keer per",
        period: "Periode",
        days: "dagen",
        weeks: "weken",
        months: "maanden",
        mg: "mg"
      },
      customInfuusSchemaTotal: {
        missingSchemaPlaceholder: "*vul eerst de benodigde gegevens in"
      },
      customMedicationSelect: {
        amount: "Hoeveelheid (ml)",
        comments: "Opmerkingen",
        admissionDays: "Dag(en) van toediening",
        checkDays: "Vink aan welke dagen van toepassing zijn"
      },
      customPatientOverviewLink: {
        inThe: "In het ",
        patientOverview: "patiëntenoverzicht",
        canFindRequests: " kunt u deze en alle andere aanvragen terugvinden."
      },
      customPdfButtons: {
        openPdf: "Open PDF",
        downloadPdf: "Download PDF"
      },
      customPrefillPatient: {
        fillInFields: "Vul het BSN en geboortdatum veld in",
        noDataFound:
          "Er konden geen gegevens worden gevonden op basis van de opgegeven combinatie van BSN en geboortedatum."
      },
      customPreSurgeryDate: {
        operationDate: "Operatiedatum",
        emergencyCost: "Spoed (besproken tarief €{emergency_cost},-)",
        emergencyProcedure: `Binnen spoedprocedure proberen wij de afgesproken kaders ({minLeadDaysOperation}-{maxLeadDaysOperation} werkdagen voorafgaand aan de operatie) aan te houden. Op basis van de opgegeven data loopt het uitvoeringsverzoek van:`,
        startDate: "Startdatum",
        endDate: "Einddatum"
      },
      customPreSurgerySchema: {
        operationDate: "Datum van de operatie",
        dateImpossible: `Op basis van de door u ingevulde operatiedatum is het niet mogelijk om
        alle toedieningen van het gekozen schema voorafgaand aan de operatie uit
        te voeren. U kunt dit aanpassen door de operatiedatum te wijzigen óf te
        kiezen voor een ander toedieningsschema.`,
        everyOtherDay: "Om de dag schema (vijf toedieningen, 1x per 2 dagen)",
        weekly: "Week schema (vier toedieningen, 1x per week)",
        other: "Anders",
        frequency: "Frequentie",
        onePerDay: "1x per dag",
        onePerTwoDays: "1x per 2 dagen",
        onePerThreeDays: "1x per 3 dagen",
        onePerFourDays: "1x per 4 dagen",
        onePerFiveDays: "1x per 5 dagen",
        onePerSixDays: "1x per 6 dagen",
        onePerSevenDays: "1x per 7 dagen",
        onePerEightDays: "1x per 8 dagen",
        startDate: "Startdatum",
        endDate: "Einddatum",
        result: "Resultaat",
        startAndEnd:
          "Aan de hand van de selectie is dit de start- en einddatum.",
        everyOtherDayDescription:
          "Het 'om de dag schema' is gebaseerd op vijf toedieningen in totaal (1x per 2 dagen). Aan de hand van uw selectie zijn de start- en einddatum als volgt.",
        weeklyDescription:
          "Het 'week schema' is gebaseerd op vier toedieningen in totaal (1x per week). Aan de hand van uw selectie zijn de start- en einddatum als volgt.",
        otherDescription:
          "Het 'anders' schema is gebaseerd op een variabele frequentie tussen de startdatum en de operatiedatum. Aan de hand van uw selectie zijn de start- en einddatum als volgt."
      },
      customRadioWithOther: {
        description: "Geef een duidelijke omschrijving"
      },
      customRadioWithWeeklySchedule: {
        amount: "Hoeveelheid (ml)",
        comments: "Opmerkingen",
        checkDays: "Vink aan welke dagen van toepassing zijn"
      },
      customStartDate: {
        startDate: "Startdatum"
      },
      customWeeklySchedule: {
        checkDays: "Vink aan welke dagen van toepassing zijn"
      },
      cancelRequestModal: {
        cancelRequest: "Aanvraag annuleren",
        requestError: `Wanneer u of Eurocept een onjuistheid heeft ontdekt in de ingediende
        aanvraag dan kunt u deze annuleren. Zonodig kunt u een nieuwe gewijzigde
        aanvraag indienen.`,
        close: "Sluiten",
        couldNotCancel: "We konden deze aanvraag niet annuleren",
        cancelled: "Aanvraag geannuleerd"
      },
      closePatientModal: {
        leaveNoSave: "Wilt u het bewerken van deze patient verlaten?",
        dataLost:
          "Als u deze pagina verlaat zonder op te slaan zullen de gegevens verloren gaan.",
        close: "Sluiten",
        continueEdit: "Doorgaan met bewerken"
      },
      closeRequestModal: {
        leaveRequest: "Wilt u deze aanvraag verlaten?",
        exitNoSave:
          "Kies hieronder wat u wilt doen met deze aanvraag.<br><br>Bij <b>Opslaan & sluiten</b> wordt de aanvraag opgeslagen als concept. Een conceptaanvraag is nog <u>niet</u> zichtbaar voor de apotheek en/of de thuiszorg. <br><br><b>Verwijderen</b> betekent dat de aanvraag in zijn geheel wordt verwijderd.",
        saveAndExit: "Opslaan & sluiten",
        delete: "Verwijderen",
        continueEdit: "Doorgaan met bewerken"
      },
      completeRequestModal: {
        completeRequest: "Aanvraag voltooien",
        noExtend:
          'Wilt u deze aanvraag niet verlengen? Dan kunt u de status hier veranderen naar "voltooid"',
        cancel: "Annuleren",
        couldNotComplete: "We konden deze aanvraag niet voltooien",
        completedRequest: "Aanvraag voltooid"
      },
      deleteRequestModal: {
        deleteRequest: "Aanvraag verwijderen",
        loseData: "Als u de huidige aanvraag verwijdert gaat uw data verloren.",
        keepRequest: "Aanvraag behouden",
        couldNotDelete: "We konden deze aanvraag niet verwijderen",
        deletedRequest: "Aanvraag verwijderd"
      },
      historyModal: {
        history: "Geschiedenis",
        historyList:
          "Hieronder vindt u de volledige geschiedenis van deze aanvraag.",
        close: "Sluiten"
      },
      patientConfirmationModal: {
        startRequest: "Aanvraag starten",
        aboutToStart: "U staat op het punt om een aanvraag te doen van",
        forPatient: " voor patiënt",
        ifCorrect:
          "Als dit klopt klik dan op doorgaan. Zo niet, sluit dan de aanvraag af.",
        close: "Afsluiten",
        continue: "Doorgaan"
      },
      repeatRequestModal: {
        repeatRequest: "Aanvraag herhalen",
        shouldRepeatRequest: "Wilt u deze aanvraag herhalen?",
        close: "Afsluiten",
        confirm: "Bevestigen",
        cantRepeat: "We konden deze aanvraag niet herhalen"
      },
      stopRequestModal: {
        stopRequest: "Aanvraag stopzetten",
        chooseEndDate:
          "Kies hieronder de gewenste stopdatum. De stopdatum moet tussen de oorspronkelijk begin- en einddatum liggen.",
        close: "Afsluiten",
        confirm: "Bevestigen",
        enterStopDate: "Geef een stopdatum op",
        cantStop: "We konden deze aanvraag niet stoppen",
        stopped: "Aanvraag gestopt"
      }
    }
  }
};
