import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from "vee-validate";
//@ts-ignore
import Fragment from "vue-fragment";
import { format, parse } from "date-fns";
import {
  formatDate,
  formatTime,
  formatDateTime,
  parseDate,
  formatWrittenDate
} from "./utils/dateUtils";
import { fullName } from "@/utils/nameUtils";
import mixpanel from "mixpanel-browser";
import VueI18n from "vue-i18n";
import { messages } from "./translations/messages";

import "./validations";

Vue.use(Fragment.Plugin);
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "nl", // set locale
  messages // set locale messages
});

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
  api_host: "https://api-eu.mixpanel.com",
  ip: false,
  save_referrer: false,
  property_blacklist: ["$initial_referrer", "$referrer", "$current_url"]
});

Vue.use(Buefy, {
  defaultIconPack: "fas",
  defaultFirstDayOfWeek: 1,
  defaultDayNames: ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
  defaultNotificationDuration: 7000,
  defaultNoticeQueue: false,
  defaultMonthNames: [
    "januari",
    "februari",
    "maart",
    "april",
    "mei",
    "juni",
    "juli",
    "augustus",
    "september",
    "oktober",
    "november",
    "december"
  ],
  defaultDateFormatter: (date: Date) => format(date, "dd-MM-yyyy"),
  defaultDateParser: (date: string) => parse(date, "dd-MM-yyyy", new Date())
});

Vue.mixin({
  methods: {
    formatDate
  }
});

Vue.filter("formatDate", formatDate);
Vue.filter("formatTime", formatTime);
Vue.filter("parseDate", parseDate);
Vue.filter("formatDateTime", formatDateTime);
Vue.filter("fullName", fullName);
Vue.filter("formatWrittenDate", formatWrittenDate);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

setInteractionMode("eager");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n
}).$mount("#app");
