
import Vue from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import { StoreState } from "@/store";
import ProfileDropdown from "@/components/common/ProfileDropdown.vue";
import { PlazaUser } from "@/types";

export default Vue.extend({
  name: "CustomHeader",
  components: { ProfileDropdown },
  data() {
    const { VUE_APP_ENABLE_SPECIFIER_SETTINGS, VUE_APP_PLAZA_URL } =
      process.env;

    return {
      specifierSettingsEnabled: VUE_APP_ENABLE_SPECIFIER_SETTINGS === "true",
      plazaUrl: VUE_APP_PLAZA_URL
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      isTokenValid: "isTokenValid",
      hasPermission: "hasPermission",
      isEpdUser: "isEpdUser"
    }),
    ...mapState({
      user: (state: any) => (state as StoreState).user!.user
    })
  },

  watch: {
    user(newUser): PlazaUser | null {
      return newUser;
    }
  },

  mounted() {
    if (!this.user && this.$route.name !== "no-access") {
      this.getUser();
    }
  },
  methods: {
    logOut(): void {
      this.$store.dispatch("logout");
      window.location.replace(process.env.VUE_APP_PLAZA_URL + "/?logout=true");
    },
    editSpecifier(): void {
      if (!this.user) return;
      this.$router.push(`/specifier/${this.user.id}`);
    },
    ...mapActions(["getUser"])
  }
});
