import * as api from "@/api";
import { PatientDossier, RequestDetails, PatientRow } from "@/types";
import { formatDate } from "@/utils/dateUtils";

export interface PatientsState {
  patients: PatientRow[];
  patient_dossier: PatientDossier | null;
  loading_patient_dossier: boolean;
}

export enum PatientActionTypes {
  GET_PATIENTS = "GET_PATIENTS",
  SET_PATIENT_DOSSIER = "SET_PATIENT_DOSSIER",
  DELETE_REQUEST = "DELETE_REQUEST",
  SET_LOADING_PATIENT_DOSSIER = "SET_LOADING_PATIENT_DOSSIER",
  UPDATE_REQUEST = "UPDATE_REQUEST"
}

export const initialStateRequest: PatientsState = {
  patients: [],
  patient_dossier: null,
  loading_patient_dossier: false
};

const getters = {};

const mutations = {
  [PatientActionTypes.GET_PATIENTS](
    state: PatientsState,
    patients: PatientRow[]
  ) {
    state.patients = patients;
  },
  [PatientActionTypes.SET_PATIENT_DOSSIER](
    state: PatientsState,
    patient: PatientDossier
  ) {
    patient.requests = patient.requests.map((r: RequestDetails) => ({
      ...r,
      start_date: formatDate(r.start_date),
      end_date: formatDate(r.end_date),
      stop_date: formatDate(r.stop_date)
    }));
    state.patient_dossier = patient;
  },
  [PatientActionTypes.SET_LOADING_PATIENT_DOSSIER](
    state: PatientsState,
    loading: boolean
  ) {
    state.loading_patient_dossier = loading;
  },
  [PatientActionTypes.DELETE_REQUEST](state: PatientsState, requestId: number) {
    if (!state.patient_dossier) return;

    const index = state.patient_dossier.requests.findIndex(
      (request: RequestDetails) => request.id === requestId
    );

    if (index !== -1) {
      state.patient_dossier.requests.splice(index, 1);
    }
  },
  [PatientActionTypes.UPDATE_REQUEST](
    state: PatientsState,
    { requestId, request }: { requestId: number; request: RequestDetails }
  ) {
    if (!state.patient_dossier?.requests) return;

    const oldRequestIndex = state.patient_dossier.requests.findIndex(
      (request) => request.id === requestId
    );

    if (oldRequestIndex === -1) return;

    const formattedRequest = {
      ...request,
      start_date: formatDate(request.start_date),
      end_date: formatDate(request.end_date),
      stop_date: formatDate(request.stop_date)
    };

    state.patient_dossier.requests[oldRequestIndex] = formattedRequest;
    state.patient_dossier.requests = [...state.patient_dossier.requests];
  }
};

const actions = {
  async getPatientDossier({ commit }: any, epin: string) {
    commit(PatientActionTypes.SET_LOADING_PATIENT_DOSSIER, true);

    const response = await api.getPatientDossier(epin);
    commit(PatientActionTypes.SET_PATIENT_DOSSIER, response?.data || {});

    commit(PatientActionTypes.SET_LOADING_PATIENT_DOSSIER, false);
  },
  removeRequestFromDossier({ commit }: any, requestId: number) {
    commit(PatientActionTypes.DELETE_REQUEST, requestId);
  },
  updateRequestInDossier(
    { commit }: any,
    { requestId, request }: { requestId: number; request: RequestDetails }
  ) {
    commit(PatientActionTypes.UPDATE_REQUEST, { requestId, request });
  }
};

export default {
  namespaced: true,
  state: initialStateRequest,
  getters,
  actions,
  mutations
};
